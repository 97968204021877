<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 " *ngIf="!idEjecutive">
                <h5 class="mb-4 text-secondary"> <i class="  fas fa-users"></i> Ingrese el número de cédula del ejecutivo de la caja
                </h5>



                <ng-container *ngIf="rate_error">
                    <div class="text-danger"> No se consiguio taza de cambio </div>
                </ng-container>

                <ng-container *ngIf="!rate_error && !rate_load">

                    <ng-container *ngIf="section == 1">

                        <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                            <form autocomplete="nope" novalidate [formGroup]="formEjecutive" (ngSubmit)="setIdEjecutive()" class="text-start">
                                <div class="row mt-3">


                                    <div class="form-group  col-4 mt-3">

                                        <div class="form-floating">
                                            <select [ngClass]="{'error': formEjecutive.controls.code_identity.invalid && formEjecutive.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                     </select>



                                            <label for="code_identity">Tipo</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="formEjecutive.get('code_identity').errors">
                                            <div *ngIf="formEjecutive.get('code_identity').errors['required'] && !formEjecutive.get('code_identity').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group  col-8  mt-3">

                                        <div class="form-floating ">
                                            <input type="text" [ngClass]="{'error': formEjecutive.controls.identity.invalid && formEjecutive.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                                            <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                          <span >Número </span>
                     </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="formEjecutive.get('identity').errors">
                                            <div *ngIf="formEjecutive.get('identity').errors['required'] && !formEjecutive.get('identity').pristine">
                                                Campo requerido
                                            </div>

                                            <div *ngIf="formEjecutive.get('identity').hasError('minlength')  && !formEjecutive.get('identity').pristine">
                                                Mínimo 6 caracteres
                                            </div>

                                            <div *ngIf="formEjecutive.get('identity').hasError('maxlength')  && !formEjecutive.get('identity').pristine">
                                                Máximo 8 caracteres
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group  col-12 mt-4 mb-2">
                                        <div class=" float-end">

                                            <button type="submit" [disabled]="!formEjecutive.valid || submit_disabled" class="btn btn-block btn-primary ">
                                        <span >Continuar</span>
                                        <span *ngIf="submit_disabled">
                                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                                        </span>
                                       </button>

                                        </div>

                                    </div>

                                    <hr *ngIf="errorMsg" class="mt-2">

                                </div>
                            </form>


                        </div>
                    </ng-container>
                </ng-container>

            </div>
            <div class=" col-12 col-md-6 offset-md-3  p-0 " *ngIf="idEjecutive">


                <h5 class="text-secondary pb-3"> <i class="  fas fa-users"></i> Crear compra financiada
                    <button *ngIf="section > 1" (click)="resetData() " type="button" class="btn-close float-end text-dark" aria-label="Close"></button>

                </h5>


                <ng-container *ngIf="rate_load">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

                <ng-container *ngIf="rate_error">
                    <div class="text-danger"> No se consiguio taza de cambio </div>
                </ng-container>

                <ng-container *ngIf="!rate_error && !rate_load">
                    <ng-container *ngIf="viewForm">
                        <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                            <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
                             
                                <div class="row mt-3">

                                    <!-- <div class=" mt-4 fw-bold">Planes</div>
                                    <div class="form-group  col-12 mt-3">
                                        <div class="form-floating">
                                            <select (change)="searchPerson = null" [ngClass]="{'error': form.controls.plan.invalid && form.controls.plan.dirty}" class="form-select" id="plan" formControlName="plan" aria-label="Floating label select example">
                                                <option  value="">Selecione</option>
                                                <option *ngFor="let item of plans"  [value]="item._id">{{item.name}}</option> 

                                     </select>
                                            <label for="currency">Plan</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('plan').errors">
                                            <div *ngIf="form.get('plan').errors['required'] && !form.get('plan').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div> -->


                                    <div class="form-group  col-12 col-md-6  mt-3">

                                        <div class="form-floating ">
                                            <input (keyup)="searchPerson = null" type="text" [ngClass]="{'error': form.controls.item.invalid && form.controls.item.dirty}" class="form-control text-capitalize" id="item" formControlName="item">
                                            <label for="item" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Código del Producto (UBi)</span>
                                    </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('item').errors">
                                            <div *ngIf="form.get('item').errors['required'] && !form.get('item').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div>

      <!-- 
                                    <div class=" mt-2 fw-bold">Documento</div>

                                    <div class="form-group  col-12 col-md-6 mt-3">
                                        <div class="form-floating">
                                            <select [ngClass]="{'error': form.controls.currency.invalid && form.controls.type_invoice.dirty}" class="form-select" id="type_invoice" formControlName="type_invoice" aria-label="Floating label select example">
                                            <option  value="invoice">Factura</option>
                                     </select>
                                            <label for="currency">Tipo documento</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('type_invoice').errors">
                                            <div *ngIf="form.get('currency').errors['required'] && !form.get('type_invoice').pristine">
                                                Campo requerido
                                            </div>
                                        </div>

                                    </div>



                             <div class="form-group  col-12 col-md-6  mt-3">

                                        <div class="form-floating ">
                                            <input (keyup)="searchPerson = null" type="text" [ngClass]="{'error': form.controls.invoice.invalid && form.controls.invoice.dirty}" class="form-control text-capitalize" id="invoice" formControlName="invoice">
                                            <label for="invoice" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Código documento</span>
                                    </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('invoice').errors">
                                            <div *ngIf="form.get('invoice').errors['required'] && !form.get('invoice').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div>
--> 
                                    <!-- <div class="form-group  col-12 col-md-6  mt-3">

                                        <div class="form-floating ">
                                            <input (keyup)="searchPerson = null" type="text" [ngClass]="{'error': form.controls.invoice_oddo.invalid && form.controls.invoice_oddo.dirty}" class="form-control text-capitalize" id="invoice_oddo" formControlName="invoice_oddo">
                                            <label for="invoice_oddo" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Código contabilidad</span>
                                    </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('invoice_oddo').errors">
                                            <div *ngIf="form.get('invoice_oddo').errors['required'] && !form.get('invoice_oddo').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div> -->



                                    <div class=" mt-3 fw-bold">Datos del cliente</div>

                                    <div class="form-group  col-4 mt-2">

                                        <div class="form-floating">
                                            <select (change)="searchPerson = null" [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                     </select>



                                            <label for="code_identity">Tipo</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                                            <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group  col-8  mt-2">

                                        <div class="form-floating ">
                                            <input (keyup)="searchPerson = null" type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                                            <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Número </span>
                                    </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                                            <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                                                Campo requerido
                                            </div>



                                            <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                                Mínimo 6 caracteres
                                            </div>

                                            <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                                Máximo 8 caracteres
                                            </div>

                                        </div>
                                    </div>


                                    <div class=" mt-4 fw-bold">Datos de la operación</div>



                                    <div class="form-group  col-4 mt-3">
                                        <div class="form-floating">
                                            <select (change)="searchPerson = null" [ngClass]="{'error': form.controls.currency.invalid && form.controls.currency.dirty}" class="form-select" id="currency" formControlName="currency" aria-label="Floating label select example">
                                            <option  value="BS">Bolivares</option>
                                            <option  value="USD">Dolares</option>

                                     </select>
                                            <label for="currency">Moneda</label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('currency').errors">
                                            <div *ngIf="form.get('currency').errors['required'] && !form.get('currency').pristine">
                                                Campo requerido
                                            </div>
                                        </div>

                                    </div>


                                    <div class="form-group  col-8  mt-3">

                                        <div class="form-floating ">
                                            <input (keyup)="searchPerson = null" currencyMask [options]="{ prefix:form?.getRawValue()?.currency+' ', thousands: ',', decimal: '.' }" (click)="moveCursorToEnd($event)" type="text" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}"
                                                class="form-control text-capitalize" id="total_amount" formControlName="total_amount">
                                            <label for="total_amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                                      <span >Monto Total</span>
                                 </label>
                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors">
                                            <div *ngIf="form.get('total_amount').errors['required'] && !form.get('total_amount').pristine">
                                                Campo requerido
                                            </div>
                                            <div *ngIf="form.get('total_amount').hasError('min')  && !form.get('total_amount').pristine">
                                                Mínimo 20 Dolares
                                            </div>


                                        </div>

                                        <div class="form-control-feedback help-block" *ngIf="!(form?.getRawValue()?.total_amount > 0) && !form.pristine">
                                            <div>
                                                Monto debe ser mayor a 0
                                            </div>

                                        </div>
                                    </div>
                                    <div class="form-group  col-12  mt-3">
                                        <label for="chage_rate" class=" label font-weight-normal text-secondary small" style="word-wrap: break-word;">
                                        <span >Tasa de cambio </span>
                                     </label>
                                        <div class=" text-dark"> USD 1 = BS {{rate?.amount | currency:'USD':'': '1.2-2'}}</div>
                                    </div>
                                    <br>
                                    <div class="form-group  col-12 mt-4 mb-2 ">
                                        <div class=" d-grid gap-2">

                                            <button type="submit" [disabled]="searchPerson || !(form?.getRawValue()?.total_amount > 0) || !form.valid || submit_disabled" class="btn  btn-outline-primary ">
                                        <span >Consultar</span>
                                        <span *ngIf="submit_disabled">
                                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                                        </span>
                                       </button>

                                        </div>

                                    </div>

                                    <hr *ngIf="errorMsg" class="mt-2">

                                    <div *ngIf="errorMsg" class="text-danger">
                                        {{errorMsg}}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="submit_disabled ">
                        <div class="w-100 text-center text-md-start">
                            <img src="./assets/public/images/loading.gif  " alt=" ">
                        </div>
                    </ng-container>

                    <ng-container *ngIf=" !submit_disabled &&  !errorMsg && searchPerson">

                        <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">


                            <div class="">
                                <div class="d-flex mb-4">

                                    <div class="">
                                        <button (click)="currencyResponse = 'BS'" [ngClass]="{'btn-secondary': currencyResponse == 'BS','btn-outline-secondary': currencyResponse != 'BS'}" class=" me-2 btn btn-sm">
                                        <span >BS</span>
                                    </button>

                                        <button (click)="currencyResponse = 'USD'" [ngClass]="{'btn-secondary': currencyResponse == 'USD','btn-outline-secondary': currencyResponse != 'USD'}" class="btn btn-sm">
                                        <span >Dolares</span>
                                    </button>

                                    </div>
                                </div>

                                <div>
                                    <span class=" text-secondary">Tipo de documento: </span>
                                    <span class=" text-dark ms-auto"> Factura </span>
                                </div>

                                <div class="mt-2">
                                    <span class=" text-secondary">Código de documento: </span>
                                    <span class=" text-dark ms-auto"> {{form?.getRawValue()?.invoice}} </span>
                                </div>

                                <!-- <div class="mt-2">
                                    <span class=" text-secondary">Código de contabilidad: </span>
                                    <span class=" text-dark ms-auto"> {{form?.getRawValue()?.invoice_oddo}} </span>
                                </div> -->

                                <div class="mt-2">
                                    <span class=" text-secondary">Cliente: </span>
                                    <span class=" text-dark ms-auto text-capitalize"> {{searchPerson?.person?.name}} {{searchPerson?.person?.lastName}}  </span>
                                </div>

                                <div class="mt-2">
                                    <span class=" text-secondary">Monto Total: </span>
                                    <span class=" text-dark"> {{searchPerson?.total_currency[currencyResponse]?.total_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></span>
                                </div>

                                <div class="mt-2">
                                    <span class=" text-secondary">Monto Inicial: </span>
                                    <span class=" text-dark"> {{searchPerson?.total_currency[currencyResponse]?.initiala_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></span>
                                </div>

                                <div class="mt-3">

                                    <pre>{{ searchPerson?.listPlans | json }}</pre>

                                    <div class=" text-secondary" *ngIf="getobjPlans">Plan: <a href="/" class="text-primary ps-2" disbled="true">Editar</a></div>
                                    <div class=" text-dark ms-auto text-capitalize mt-2"> {{getobjPlans()?.name}}</div>

                                    <div class="mt-2">
                                        <span class=" mt-2 badge rounded-pill bg-secondary2 me-2 ">Monto minimo : {{getobjPlans()?.min_amount}} USD</span>
                                        <span class=" mt-2 badge rounded-pill bg-secondary2 me-2">Cuotas minimas  : {{getobjPlans()?.min_number_installment}}</span>
                                        <span class=" mt-2 badge rounded-pill bg-secondary2 me-2">Inicial  : {{getobjPlans()?.initial_installMent?'Si':'No'}}</span>
                                        <span class=" mt-2 badge rounded-pill bg-secondary2 me-2" *ngIf="getobjPlans()?.initial_installMent">Monto inicial : {{getobjPlans()?.amount_initial_installMent}}{{getobjPlans()?.type_amount_initial_installMent == 'P'?'%':' USD'}}</span>
                                        <span class=" mt-2 badge rounded-pill bg-secondary2 me-2 " *ngIf="!getobjPlans()?.item?.calendar">Cuota cada: {{getobjPlans()?.calendar_time}}D</span>
                                        <span class=" mt-2 badge rounded-pill bg-secondary2 me-2">Recalculado de inicial  : {{getobjPlans()?.recalculate_initial?'Si':'No'}}</span>
                                    </div>



                                </div>


                                <div class=" d-flex text-dark pt-4 " *ngFor=" let item of searchPerson?.installments;let i = index">
                                    <div class="pb-3 mb-0 small lh-sm border-bottom  w-100">
                                        <div class="d-flex justify-content-between">
                                            <div class="">

                                                <div class="text-secondary text-capitalize fw-bold ">
                                                    {{ item?.order
                                                    < 1 ? 'Inicial': 'cuota '+item?.order}}</div>

                                                        <div class="text-secondary text-capitalize small pt-1">
                                                            {{item?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}
                                                        </div>

                                                </div>
                                                <div class="ms-auto">


                                                    <div class=" fw-bold">{{item?.amount | currency:'USD':'': '1.2-2'}} USD </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>




                                <div class=" mt-5 d-grid gap-2">
                                    <button [disabled]="submit_download_create" (click)="creditSave()" class="btn  btn-primary " *ngIf="!pagare">
                                    <span > Crear </span>
                                    <span *ngIf="submit_download_create">
                                    <i class="fa  fa-spinner fa-spin ms-2"></i>
                                    </span>
                                </button>
                                    <!-- <button [disabled]="submit_download_disabled" (click)="downloadFile(pagare.url, 'pdf', idInvoice+'.pdf')" class="btn  btn-primary btn-block" *ngIf="pagare">
                                    <span > Descargar Pagaré </span>
                                    <span *ngIf="submit_download_disabled">
                                        <i class="fa  fa-spinner fa-spin ms-2"></i>
                                    </span>
                                </button> -->

                                    <button [disabled]="submit_download_disabled" (click)="continuar()" class="btn btn-block btn-outline-primary" *ngIf="pagare">
                                            <span > Continuar </span>
                                            
                                        </button>




                                </div>
                            </div>



                    </ng-container>

                    <ng-container *ngIf="section == 3">

                        <div class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                            <img *ngIf="searchPerson?.person?.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                            <img *ngIf="searchPerson?.person?.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

                        </div>

                        <div class="text-center mb-5">
                            <div class="text-capitalize h4 text-dark">{{searchPerson?.person?.name}} {{searchPerson?.person?.lastName}}</div>
                            <div> {{identityResponse}}</div>
                        </div>

                        <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
                            <div class="row rmt-2">
                                <div class="col-12">
                                    <div class="float-end">
                                        <button (click)="currencyResponse = 'BS'" [ngClass]="{'btn-secondary': currencyResponse == 'BS','btn-outline-secondary': currencyResponse != 'BS'}" class=" me-2 btn btn-sm">
                                        <span >BS</span>
                                    </button>

                                        <button (click)="currencyResponse = 'USD'" [ngClass]="{'btn-secondary': currencyResponse == 'USD','btn-outline-secondary': currencyResponse != 'USD'}" class="btn btn-sm">
                                        <span >Dolares</span>
                                    </button>

                                    </div>
                                    <div class="small text-secondary">Credito total</div>
                                    <h5 class="fw-bolder text-dark">{{searchPerson?.total_currency[currencyResponse]?.total_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></h5>
                                </div>


                                <div class="col-6">
                                    <div class="small text-secondary">ID Transaccion</div>
                                    <div class=" text-dark">{{idInvoice }}</div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Estatus</div>
                                    <div class="text-dark">Pendiente</div>
                                </div>
                                <div class="col-12 ">
                                    <div class="small text-secondary">Plan</div>
                                    <div class="text-dark">50% Inicial min.</div>
                                </div>
                                <div class="col-12">
                                    <div class="small text-secondary">Cuotas</div>
                                    <div class=" text-dark">3</div>
                                </div>
                                <div class="col-6 ">
                                    <div class="small text-secondary">Inicial</div>
                                    <div class=" text-dark">{{searchPerson?.total_currency[currencyResponse]?.initiala_amount | currency:currencyResponse:'': '1.2-2'}} <span class="small">{{currencyResponse}}</span></div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Total pendiente</div>
                                    <div class=" text-dark">{{searchPerson?.outstanding_balance | currency:'USD':'': '1.2-2'}} USD</div>

                                </div>
                            </div>
                        </div>
                    </ng-container>

                </ng-container>

                </div>
            </div>
        </div>
    </div>