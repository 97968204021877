<ng-template #showPrivatePlans let-offcanvas>
  <div class="p-5">
    <div class="h5 mb-4">
      Seleccione un plan para agregar:
    </div>
    <div class="modal-body " >
        <div style="   overflow-x: hidden; overflow-y: scroll; max-height: 600px;">

      <div *ngFor="let item of privatePlans" >
        <div    class="select-box mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm border" (click)="selectPlan(item)" [ngClass]="{ 'opaque-plan': isPlanAdded(item._id) }" *ngIf="!customerPlans_obj[item?._id] && !item?.params?.customer_inactive" >
            <div class="d-flex">
                <div class="text-primary fw-bold">
                    {{item?.code}}
                </div>
                <div class="ms-auto text-end small">
                   
                    <div class="me-4">
                        <span *ngIf="item?.params?.init_date">Inicio : {{item?.params?.init_date | date: 'dd/MM/yyyy'}} </span><br>
                            <span *ngIf="item?.params?.end_date"> Fin: {{item?.params?.end_date | date: 'dd/MM/yyyy'}} </span>
                    </div>
                </div>
            </div>
            <div class="mt-1 ">
                {{item?.name}} / {{item?.version?.internal_code}}
            </div>
            <div class="text-secondary small">
                {{item?.description}}
            </div>
            
            <div class="mt-4">

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Status: {{item?.state}}</span>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Visibilidad: {{item?.visibility}}</span>


                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Inicial  : {{item?.initial?.status?'Si':'No'}}
                    <span *ngIf="item?.initial?.status"> / {{item?.initial.amount}}<span>{{item?.initial?.type == "percentage"?'%':'USD'}}</span>
                 </span>
                </span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Multicompra : {{item?.params?.multiple_invoices?.status? 'Si':'No'}} 
                    <span *ngIf="item?.params?.multiple_invoices?.count"> / {{item?.params?.multiple_invoices?.count}}</span>

                </span>             
                   <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Prioridad  : {{item?.params?.priority}}</span>

                    <br>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount">Compra minima : {{item?.params?.min_amount}} USD</span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount_base">Minimo a financiar : {{item?.params?.min_amount_base}} USD</span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.max_amount_base">Maximo a financiar : {{item?.params?.max_amount_base}} USD</span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_min">Cupo  minimo: {{item?.params?.current_quota_min}} USD</span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_max">Cupo  maximo: {{item?.params?.current_quota_max}} USD</span>

                <div *ngIf="item?.params?.filters?.general?.invoices?.shop || item?.params?.filters?.dinamic_code?.person">
                    <div class="fw-bold mt-4">Filtros </div>

                </div>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.general?.invoices?.shop">
                     Tiendas {{item?.params?.filters?.general?.invoices?.shop?.type == 'white_list'?'Permitidas':'Excluidas'}}: 
                    <span *ngFor="let shop of item?.params?.filters?.general?.invoices?.shop?.list; let i=index">

                        <span *ngIf="i>0"> ,</span>
                        {{shop}}
                    </span>
                
                </span>


                <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.dinamic_code?.person">
                    Codigos {{item?.params?.filters?.dinamic_code?.person?.type == 'white_list'?'Permitidos':'Excluidos'}}: 
                   <span *ngFor="let shop of item?.params?.filters?.dinamic_code?.person?.list; let i=index">

                       <span *ngIf="i>0"> ,</span>
                       {{shop}}
                   </span>
               
               </span>






                <div class="fw-bold  mt-4">Frecuencias de pago </div>
                <ul>
                  <li *ngFor="let term of item.payment_terms" class="small">
                    {{ term.name }}
                  </li>
                </ul>
             

                <div *ngIf="item?.params?.invoices_plans">
                <div class="fw-bold mt-4">Solicitudes activas </div>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.invoices_plans?.invoices?.length > 0">Activas: {{item?.params?.invoices_plans?.invoices?.length}}</span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.invoices_plans?.totalDueBalance">Total: {{item?.params?.invoices_plans?.totalDueBalance}} USD</span>
                <ul class="mt-3">
                  <li *ngFor="let item3 of item?.params?.invoices_plans?.invoices " class="small">
                   <b>{{ item3.identifier }}</b> <span *ngIf="item3?.version_plan"> / {{item3?.version_plan }} </span> : monto financiado <b> {{ item3.dueBalance }} USD </b> 
                  </li>
                </ul>
                
                 </div>

                 <div class="fw-bold mt-4">Paramestros Personales</div>


             <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.multiple_invoices?.count">Cantidad de compras restantes  : {{item?.params?.multiple_invoices?.count  -  item?.params?.invoices_plans?.invoices?.length }}</span>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.max_amount_base_total">Monto disponible del plan : {{item?.params?.max_amount_base_total}} USD</span>                
                <span class="badge rounded-pill bg-success me-2 mt-2" *ngIf="item?.params?.max_amount_base_total_quota">Cupo disponible : {{item?.params?.max_amount_base_total_quota}} USD</span>


            </div>

            

        </div>
      </div>
    </div>
</div>

  </div>
</ng-template>

<div class="mt-md-3 p-3">
<div class=" col-12 col-md-8 offset-md-2">

            <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Planes
            </h5>


        

            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

            </div>


            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
            </div>


           

            <div *ngIf="!loading && person?.person" class="text-center mb-3">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
                <div> {{getEmailContact(person)}}</div>


</div>
        
<div *ngIf="loading  || plans_loading" class="w-100 text-center mt-2">
    <img src="./assets/public/images/loading.gif  " alt=" ">
</div>

<div *ngIf="!loading && !plans_loading" class="col-12 mt-5 mb-3">
    <div class="row">
          <!--
         <div class="col-8 h5 pt-2 pb-2 text-muted">
                Privados
            </div>--> 

        <!-- revisar esto -->
        <div class="col-12 text-end">
            <button class="btn btn-primary btn-sm" (click)="pocisionBottom(showPrivatePlans)">
                Agregar plan
            </button>
        </div>
    </div>
</div>

    <ng-container   *ngFor="let item of privatePlans">

            <div class="mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm" *ngIf="customerPlans_obj[item?._id] ||  item?.params?.customer_inactive">
                <div [ngClass]="{'position-relative': item?.params?.customer_inactive}" >

            <div  *ngIf="item?.params?.customer_inactive" class="overlay"></div>

                <div class="search-results">
                            <div class="ms-auto text-end small">
                <div class="cursor-pointer float-end"  *ngIf="!item?.params?.customer_inactive">
                    <i
                    class="far fa-trash-alt text-danger"
                    style="font-size: 18px; cursor: pointer !important;"
                    (click)="removePlan(item)"
                    ></i>
                </div>
                </div>
            
                <div >
                    <div class="d-flex">
                        <div class="text-primary fw-bold">
                            {{item?.code}} <span class="text-danger fw-normal" *ngIf="item?.params?.customer_inactive"> (No disponible)</span>
                        </div>
                        <div class="ms-auto text-end small">
                           
                            <div class="me-4">
                                <span *ngIf="item?.params?.init_date">Inicio : {{item?.params?.init_date | date: 'dd/MM/yyyy'}} </span><br>
                            <span *ngIf="item?.params?.end_date"> Fin: {{item?.params?.end_date | date: 'dd/MM/yyyy'}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-1 ">
                        {{item?.name}} / {{item?.version?.internal_code}}
                    </div>
                    <div class="text-secondary small">
                        {{item?.description}}
                    </div>
                    
                    <div class="mt-4">

                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Status: {{item?.state}}</span>

                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Visibilidad: {{item?.visibility}}</span>

                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Inicial  : {{item?.initial?.status?'Si':'No'}}
                            <span *ngIf="item?.initial?.status"> / {{item?.initial.amount}}<span>{{item?.initial?.type == "percentage"?'%':'USD'}}</span>
                         </span>
                        </span>
                        
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Multicompra : {{item?.params?.multiple_invoices?.status? 'Si':'No'}} 
                            <span *ngIf="item?.params?.multiple_invoices?.count"> / {{item?.params?.multiple_invoices?.count}}</span>
    
                        </span>                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Prioridad  : {{item?.params?.priority}}</span>

                            <br>
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount">Compra minima : {{item?.params?.min_amount}} USD</span>
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount_base">Minimo a financiar : {{item?.params?.min_amount_base}} USD</span>
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.max_amount_base">Maximo a financiar : {{item?.params?.max_amount_base}} USD</span>
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_min">Cupo  minimo: {{item?.params?.current_quota_min}} USD</span>
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_max">Cupo  maximo: {{item?.params?.current_quota_max}} USD</span>

                        <div *ngIf="item?.params?.filters?.general?.invoices?.shop || item?.params?.filters?.dinamic_code?.person">
                            <div class="fw-bold mt-4">Filtros </div>

                        </div>

                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.general?.invoices?.shop">
                             Tiendas {{item?.params?.filters?.general?.invoices?.shop?.type == 'white_list'?'Permitidas':'Excluidas'}}: 
                            <span *ngFor="let shop of item?.params?.filters?.general?.invoices?.shop?.list; let i=index">

                                <span *ngIf="i>0"> ,</span>
                                {{shop}}
                            </span>
                        
                        </span>


                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.dinamic_code?.person">
                            Codigos {{item?.params?.filters?.dinamic_code?.person?.type == 'white_list'?'Permitidos':'Excluidos'}}: 
                           <span *ngFor="let shop of item?.params?.filters?.dinamic_code?.person?.list; let i=index">

                               <span *ngIf="i>0"> ,</span>
                               {{shop}}
                           </span>
                       
                       </span>






                        <div class="fw-bold  mt-4">Frecuencias de pago </div>
                        <ul>
                          <li *ngFor="let term of item.payment_terms" class="small">
                            {{ term.name }}
                          </li>
                        </ul>


        
                        <div *ngIf="item?.params?.invoices_plans">
                        <div class="fw-bold mt-4">Solicitudes activas </div>
        
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.invoices_plans?.invoices?.length > 0">Activas: {{item?.params?.invoices_plans?.invoices?.length}}</span>
                        <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.invoices_plans?.totalDueBalance">Total: {{item?.params?.invoices_plans?.totalDueBalance}} USD</span>
                        <ul class="mt-3">
                          <li *ngFor="let item3 of item?.params?.invoices_plans?.invoices " class="small">
                           <b>{{ item3.identifier }}</b> <span *ngIf="item3?.version_plan"> / {{item3?.version_plan }} </span> : monto financiado <b> {{ item3.dueBalance }} USD </b> 
                          </li>
                        </ul>
                        
                         </div>

                         <div class="fw-bold mt-4">Paramestros Personales</div>


                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.multiple_invoices?.count">                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.multiple_invoices?.status">Cantidad de compras restantes  : {{item?.params?.multiple_invoices?.count  -  item?.params?.invoices_plans?.invoices?.length }}</span>
                 : {{item?.params?.multiple_invoices?.count  -  item?.params?.invoices_plans?.invoices?.length }}</span>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Monto disponible del plan : {{item?.params?.max_amount_base_total}} USD</span>                
                <span class="badge rounded-pill bg-success me-2 mt-2" >Cupo disponible : {{item?.params?.max_amount_base_total_quota}} USD</span>

                    </div>

                </div>
            </div>


                <div>
                    
                </div>

                </div>
                        
        </div>
    </ng-container>

    <!--
           <div *ngIf="!isCustomerPlans_obj" class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">
        <p>Este cliente no tiene planes privados asociados.</p>
    </div>

    -->

<div *ngIf="!loading && !plans_loading " class="col-12 mt-5 mb-3">
    <!-- <div class="input_group">
        <div class="form-floating">
            <div class="h5 text-muted">
                Públicos
            </div>
        </div>
    </div>-->

    <div class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm" *ngIf="publicPlans?.length > 0">
        <ng-container *ngFor="let item of publicPlans">
            
            <div [ngClass]="{'position-relative': item?.params?.customer_inactive}" >
                <div  *ngIf="item?.params?.customer_inactive" class="overlay"></div>

                <div class="d-flex">
                    <div class="text-primary fw-bold">
                        {{item?.code}} <span class="text-danger fw-normal" *ngIf="item?.params?.customer_inactive"> (No disponible)</span>
                    </div>
                    <div class="ms-auto text-end small">
                       
                        <div class="me-4">
                            <span *ngIf="item?.params?.init_date">Inicio : {{item?.params?.init_date | date: 'dd/MM/yyyy'}} </span><br>
                            <span *ngIf="item?.params?.end_date"> Fin: {{item?.params?.end_date | date: 'dd/MM/yyyy'}} </span>
                        </div>
                    </div>
                </div>
                <div class="mt-1 ">
                    {{item?.name}} / {{item?.version?.internal_code}}
                </div>
                <div class="text-secondary small">
                    {{item?.description}}
                </div>
                
                <div class="mt-4">

                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Status: {{item?.state}}</span>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Visibilidad: {{item?.visibility}}</span>

                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Inicial  : {{item?.initial?.status?'Si':'No'}}
                        <span *ngIf="item?.initial?.status"> / {{item?.initial.amount}}<span>{{item?.initial?.type == "percentage"?'%':'USD'}}</span>
                     </span>
                    </span>

                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Multicompra : {{item?.params?.multiple_invoices?.status? 'Si':'No'}} 
                        <span *ngIf="item?.params?.multiple_invoices?.count"> / {{item?.params?.multiple_invoices?.count}}</span>

                    </span>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Prioridad  : {{item?.params?.priority}}</span>

                        <br>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount">Compra minima : {{item?.params?.min_amount}} USD</span>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.min_amount_base">Minimo a financiar : {{item?.params?.min_amount_base}} USD</span>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.max_amount_base">Maximo a financiar : {{item?.params?.max_amount_base}} USD</span>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_min">Cupo  minimo: {{item?.params?.current_quota_min}} USD</span>
                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.current_quota_max">Cupo  maximo: {{item?.params?.current_quota_max}} USD</span>

                    <div *ngIf="item?.params?.filters?.general?.invoices?.shop || item?.params?.filters?.dinamic_code?.person">
                        <div class="fw-bold mt-4">Filtros </div>

                    </div>

                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.general?.invoices?.shop">
                         Tiendas {{item?.params?.filters?.general?.invoices?.shop?.type == 'white_list'?'Permitidas':'Excluidas'}}: 
                        <span *ngFor="let shop of item?.params?.filters?.general?.invoices?.shop?.list; let i=index">

                            <span *ngIf="i>0"> ,</span>
                            {{shop}}
                        </span>
                    
                    </span>


                    <span class="badge rounded-pill bg-secondary2 me-2 mt-2 " *ngIf="item?.params?.filters?.dinamic_code?.person">
                        Codigos {{item?.params?.filters?.dinamic_code?.person?.type == 'white_list'?'Permitidos':'Excluidos'}}: 
                       <span *ngFor="let shop of item?.params?.filters?.dinamic_code?.person?.list; let i=index">

                           <span *ngIf="i>0"> ,</span>
                           {{shop}}
                       </span>
                   
                   </span>

                    <div class="fw-bold  mt-4">Frecuencias de pago </div>
                    <ul>
                      <li *ngFor="let term of item.payment_terms" class="small">
                        {{ term.name }}
                      </li>
                    </ul>

                </div>

                

                <div *ngIf="item?.params?.invoices_plans">
                <div class="fw-bold mt-4">Solicitudes activas </div>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.invoices_plans?.invoices?.length > 0">Activas: {{item?.params?.invoices_plans?.invoices?.length}}</span>
                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.invoices_plans?.totalDueBalance">Total: {{item?.params?.invoices_plans?.totalDueBalance}} USD</span>
                <ul class="mt-3">
                  <li *ngFor="let item3 of item?.params?.invoices_plans?.invoices " class="small">
                   <b>{{ item3.identifier }}</b> <span *ngIf="item3?.version_plan"> / {{item3?.version_plan }} </span> : monto financiado <b> {{ item3.dueBalance }} USD </b> 
                  </li>
                </ul>
                
                 </div>

                 <div class="fw-bold mt-4">Paramestros Personales</div>


                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.multiple_invoices?.count">                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.params?.multiple_invoices?.status">Cantidad de compras restantes  : {{item?.params?.multiple_invoices?.count  -  item?.params?.invoices_plans?.invoices?.length }}</span>
                : {{item?.params?.multiple_invoices?.count  -  item?.params?.invoices_plans?.invoices?.length }}</span>

                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" >Monto disponible del plan : {{item?.params?.max_amount_base_total}} USD</span>                
                <span class="badge rounded-pill bg-success me-2 mt-2" >Cupo disponible : {{item?.params?.max_amount_base_total_quota}} USD</span>


               </div>
            <div class="border-bottom pb-4 mb-4"></div>
        </ng-container>

    </div>

    <div *ngIf="publicPlans?.length < 1 && !plans_loading" class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">
        <p>No se encontraron planes publicos disponibles</p>
    </div>
    
</div>



