<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Datos del Perfil
                                <span *ngIf="view == 2" class="text-dark"> (Direcciones)</span>
                                <span *ngIf="view == 3" class="text-dark"> (Condición Laboral)</span>
                                <span *ngIf="view == 4" class="text-dark"> (Ingresos Mensuales)</span>
                                <span *ngIf="view == 5" class="text-dark"> (Medios de pago)</span>
                                <span *ngIf="view == 6" class="text-dark"> (Editar Medio de pago)</span>
                                <span *ngIf="view == 7" class="text-dark"> (Datos basicos)</span>


                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view !== 6?view = 1: view = 5 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <!-- componente inicial -->
                    <app-data-profile  [estatus_approve]="analysis_response" [person]="person" [code_person]="person?.person?.code" *ngIf="view == 1" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-data-profile>

                    <app-payment-method   [estatus_approve]="analysis_response"  [code_person]="person?.person?.code" *ngIf="view == 5" (resetStatus)="setResetStatus($event)" (editView)="editPaymentMethod($event)" (changeView)="setChangView($event)"></app-payment-method>
                    <app-address  [estatus_approve]="analysis_response"  [code_person]="person?.person?.code" *ngIf="view == 2" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-address>
                    <app-working-condition [code_person]="person?.person?.code" *ngIf="view == 3" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-working-condition>
                    <app-income   [code_person]="person?.person?.code" *ngIf="view == 4" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-income>
                    <app-payment-method-edit *ngIf="view == 6" [dataEdit]="current_payment_method_id" (resetStatus)="setResetStatus($event)" (changeView)="setChangView($event)"></app-payment-method-edit>

                    <app-edit-data-base   [estatus_approve]="analysis_response"  [person]="person" [code_person]="person?.person?.code" *ngIf="view == 7" (resetStatus)="setResetStatus($event)" (editView)="editBaseData($event)" (changeView)="setChangViewPerson($event)"></app-edit-data-base>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #content2 let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Detalles de aprobación

                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view !== 6?view = 1: view = 5 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <div *ngFor="let item of approve_type_person" class="mt-4">

                        <div>
                            <div class="text-success float-end" > {{item?.approve_status}}</div>

                            <div class="fw-bold" > {{item?.approve_type?.description ?item?.approve_type?.description:item?.approve_type?.name}}</div>

                        </div>

                        <div class="small">

                            <div class="" > {{item?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                        </div>

                        <div class="mt-1 small text-secondary" *ngIf="item?.approve_type?.name  == 'BURO'">
                            Puntaje :
                            <span *ngIf="item.response?.puntaje"> {{item.response?.puntaje}} </span>
                            <span *ngIf="item.response?.Informe?.Score?.dolar?.puntaje"> {{item.response?.Informe?.Score?.dolar?.puntaje}} </span>





                        </div>

                        <div class="mt-1 " *ngIf="item?.approve_type?.name  == 'MANUAL_SYSTEM'">
                            <div class="text-dark mt-2 small" >Responsable: {{item?.executiveFullName}} </div>

                            <div  *ngIf="item.response?.description" >Descripción: {{item.response?.description}} </div>

                        </div>

                        <div class="mt-1 " *ngIf="item?.approve_type?.name  == 'IDENTITY_DATA_COMPARISON'">

                            <div  *ngIf="item.response?.biometricRefusedMsg" >{{item.response?.biometricRefusedMsg}} </div>

                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #content3 let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">

                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view !== 6?view = 1: view = 5 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body mb-3">
        <div class=" container pb-5">

            <ng-container *ngIf="!loading_bio">

            <div class="d-flex justify-content-between">
                <div class="h5 text-secondary mt-2"> Pre-registro</div>
                <!-- <button class="btn btn-secondary">Reconsiderar</button> -->
            </div>



            <div class=" row p-3 mt-4" style="border: 1px solid #ccc;">
                <div class=" col-12 ">



                    <p class="card-text"> <span class="text-capitalize fw-bold">{{biometria_data?.person?.name}} {{biometria_data?.person?.lastName}}</span> <br>{{getIdentity(person)}}<br> {{biometria_data?.person?.gender == 'M'?'Masculino':'Femenino'}}   <br> Fecha de nacimiento {{biometria_data?.person?.birth_date | date: 'dd/MM/yyyy'}}, <br>
                        {{ person.person.marital_status.name }}
                    </p>

                </div>
            </div>

            <div class="d-flex justify-content-between mt-5">
                <div class="d-flex justify-content-between align-items-center">
                <div class="h5 text-secondary"> Biometria</div>
                <div *ngIf="!loadingBiometric && section_transaction && person?.person && biometria_data?.person?.person_customer?.biometric_enabled">
                    <span class="badge bg-warning text-dark p-2 ms-3 mb-1">Biometría Habilitada</span>
                </div>
                </div>
                <div class="d-flex justify-content-between">
                <button [disabled]="biometria_data?.person?.person_customer?.biometric_enabled" class="btn btn-primary mb-4" (click)="enableBiometric(person?.person.code)">Habilitar biometría</button>
                <div *ngIf="loadingBiometric" class="ms-2">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                </div>
                </div>
            </div>

            <div class="  row p-3" style="border: 1px solid #ccc;">

                <div class="col-md-4 mt-4  col-12 text-start">

                    <p class="card-text">

                        <span class="text-capitalize fw-bold">
                            {{ biometria_data?.person_biometric?.ocr_data?.givenNames}}

                            {{ biometria_data?.person_biometric?.ocr_data?.surname}}
                        </span>

                        <br>  {{ biometria_data?.person_biometric?.ocr_data?.documentNumber}}

                        <br> Fecha de nacimiento: {{biometria_data?.person_biometric?.ocr_data?.dateOfBirth}}
                        <br> {{biometria_data?.person_biometric?.ocr_data?.maritalStatus}}
                        <br>

                        <span *ngIf="biometria_data?.person_biometdateOfExpiryric?.ocr_data?.dateOfIssue">Fecha de Expedición: {{biometria_data?.person_biometdateOfExpiryric?.ocr_data?.dateOfIssue}} </span>
                        <br>
                        <span *ngIf="biometria_data?.person_biometdateOfExpiryric?.ocr_data?.dateOfIssue">Fecha de Expiración: {{biometria_data?.person_biometdateOfExpiryric?.ocr_data?.dateOfExpiry}} </span>


                    </p>

                </div>
                <!-- <div class="col-md-2 mt-4 col-12 text-end">
                <button *ngIf="!biometria_data?.person?.file_identity && !biometria_data?.person?.file_selfie" class="btn" (click)="refreshBiometricPhoto(biometria_data?.person.code, 'SELFIE')">
                    <i class="fas fa-sync-alt text-primary"></i>
                </button>
                </div> -->

                <div class="col-md-4  mt-4 col-12 text-center" >
                    <div class="pb-1 fw-bold">Documento de Identidad</div>
                    <div class="pb-3"><span class="text-secondary" *ngIf="biometria_data?.person?.file_identity?.date">Fecha: </span>{{biometria_data?.person?.file_identity?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                    <img  class="w-100  "  *ngIf=" biometria_data?.person?.file_identity" [src]="sanitize( biometria_data?.person?.file_identity?.url)">

                    <span *ngIf="!biometria_data?.person?.file_identity && !loading"> No se encontro documento
                    <!-- agregar el boton y pasar param -->
                    <button *ngIf="ciClickCounter < 3" class="btn" (click)="refreshBiometricPhoto(biometria_data?.person.code, 'CI')">
                            <i class="fas fa-sync-alt text-primary"></i>
                        </button>
                    </span>
                    <div *ngIf="showCiMsg" class="text-danger mt-2">
                        TrustX no está retornando la imagen, por favor contacte a un admin.
                    </div>
                    <div *ngIf="loading" class="mt-2">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                </div>

                <div class="col-md-4  mt-4  col-12 text-center">
                    <div class="pb-1 fw-bold">Selfie</div>
                    <div class="pb-2"><span class="text-secondary" *ngIf="biometria_data?.person?.file_selfie?.date">Fecha: </span>{{biometria_data?.person?.file_selfie?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                    <img *ngIf=" biometria_data?.person?.file_selfie"  style="width: 50%;" [src]="sanitize( biometria_data?.person?.file_selfie?.url)">
                    <span *ngIf="!biometria_data?.person?.file_selfie && !loading_img"> No se encontro selfie
                        <!-- agregar el boton y pasar param -->
                        <button class="btn" (click)="refreshBiometricPhoto(biometria_data?.person.code, 'SELFIE')">
                            <i class="fas fa-sync-alt text-primary"></i>
                        </button>
                        <!-- <button *ngIf="!biometria_data?.person?.file_identity && !biometria_data?.person?.file_selfie" class="btn btn-primary mx-3" (click)="refreshBiometricPhoto(biometria_data?.person.code, '')">
                            Refrescar todas
                        </button> -->
                    </span>
                    <div *ngIf="showSelfieMsg" class="text-danger mt-2">
                        TrustX no está retornando la imagen, por favor contacte a un admin.
                    </div>
                    <div *ngIf="loading_img" class="mt-2">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                </div>



                <div class="col-12 h5 mt-5">

                    <button class="btn p-0" (click)="refreshBiometricStatus(biometria_data?.person.code)" *ngIf="!(declinedStatus || approvedStatus || abandonedStatus || biometria_data?.person?.person_customer?.biometric_enabled)">
                        <i *ngIf="!statusLoading" class="fas fa-sync-alt text-primary me-2"></i>
                        <div *ngIf="statusLoading" class="mt-2">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                    </button>
                    <span class="text-secondary">Estatus: </span>
                    <span>{{biometria_data?.person_biometric?.status}} </span>
                    <div *ngIf="showStatusMsg" class="text-danger mt-2 h6 mb-2">No hay actualización de estatus en TrustX</div>
                    <div class="h6">{{biometria_data?.person_biometric?.refused_msg}}</div>

                </div>


                <div class="col-12 ">
                    <span class="text-secondary">Instancia: </span>
                    <span class="text-primary cursor-pounter" (click)="redirectIMG(biometria_data?.person_biometric?.url)" >{{biometria_data?.person_biometric?.process_instance_id}} </span>

                </div>



                <div class="col-12 ">
                    <span class="text-secondary">Fecha: </span>
                    <span>     {{biometria_data?.person_biometric?.update_date | date: 'dd/MM/yyyy h:mm:ss a'}}
                    </span>

                </div>



            </div>

        </ng-container>


        <ng-container *ngIf="!loading_bio_h && biometrics_h?.length > 0">

            <div class="h5 text-secondary mt-5"> Historial de Biometria</div>

            <div class=" row p-3 mt-4" style="border: 1px solid #ccc;">
                <div class=" col-12  "   *ngFor="let item of biometrics_h; let i=index">

                    <div class="mb-2">


                        <span>     {{item?.entryDate | date: 'dd/MM/yyyy h:mm:ss a'}}
                        </span>
                        <span class="text-primary cursor-pounter pe-2" (click)="redirectIMG(item?.url)" >{{item?.processInstanceId}} </span>

                    </div>

                </div>


            </div>
        </ng-container>

        <ng-container *ngIf="_auth.IsPermitid(['OWNER'])  " >

            <div *ngIf="manual_verification_list" class="h5 text-secondary mt-5"> Validaciones</div>





        <div *ngIf="manual_verification_list" class="pb-5">

            <div class="fw-bold">
                {{manual_verification_list?.user?.name}} {{manual_verification_list?.user?.lastName}}

            </div>
            <div>
                Respuesta : {{manual_verification_list.verified?'Validado':'No validado'}}
            </div>
            <div *ngIf="manual_verification_list.refused_msg">
                Descripcion : {{manual_verification_list.refused_msg}}
            </div>

         <!-- <pre>{{ manual_verification_list | json }}</pre>-->
        </div>


        <div>
            <div class="  row col-12" *ngIf="showButtons">
            <div class=" row p-3 mt-4" style="border: 1px solid #ccc;" *ngIf="inReview || declinedStatus">


                    <div class="mb-2 float-end d-flex justify-content-between">

                       Verificacion de Identidad

                       <div>
                            <button  type="button" class="btn btn-primary  mb-3 me-2 " [disabled]="loading_mv" (click)="setManual_verification(true)">Si, verificar </button>

                            <button  type="button" class="btn btn-danger  mb-3" [disabled]="loading_mv"  (click)="setManual_verification(false)" >No, verificar</button>
                        </div>
                    </div>




                </div>



            </div>
            </div>
        </ng-container>











        <ng-container *ngIf="loading_bio || loading_bio_h">
            <div class="w-100 text-center mt-2">
                <img src="./assets/public/images/loading.gif  " alt=" ">
            </div>
        </ng-container>



        </div>
    </div>
</ng-template>




<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">


            <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Onboarding
            </h5>

            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

            </div>


            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(imgPerson)">
            </div>



            <div *ngIf="!loading && person?.person" class="text-center mb-5">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
                <div> {{getEmailContact(person)}}</div>


                <div class="pt-2" *ngIf="person?.person?.direct_debit_afiliation"><span class="fas fa-check-circle text-success  fz-20"></span> {{ 'Domiciliado ' }} <b><span class="font-weight-bold">{{ person?.person?.direct_debit_afiliation?.bank?.name }}</span></b></div>

                <!-- <div class="pt-2" *ngIf="domiciliation_current?.bank?.name"><span class="fas fa-check-circle text-success  fz-20"></span> {{ 'Domiciliado ' }} <span class="font-weight-bold">{{ domiciliation_current?.bank?.name }}</span></div> -->
            </div>

            <ng-container *ngIf="!loading && person?.person" class="mt-1">

                <div class=" mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto" *ngIf="section_transaction">
                    <div *ngFor="let item of section_transactions" class="position-relative">


                        <label *ngIf=" (item.conditionSection == 'COMPLETE' || item.visibility == true ||  item?.code == 'PROFILE_DATA' || status_approved) " class="list-group-item py-3 " for="listGroupRadioGrid1">

                            <ng-container >
                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE' || item?.code == 'PRE_REGISTER' ">


                                    <span *ngIf="item?.code != 'BIOMETRIC' && (( item?.code == 'PROFILE_DATA' && item?.visibility == true && section_transaction[item?.code]?.condition ==  'COMPLETE') || (item?.code == 'BURO' && !loading_buro && section_transaction[item?.code]?.condition ==  'COMPLETE') || (item?.code != 'PROFILE_DATA' && item?.code != 'BURO' && section_transaction[item?.code]?.condition ==  'COMPLETE'))" class="fas fa-check-circle text-success float-end fz-25"></span>

                                    <span *ngIf="(item?.code == 'BIOMETRIC' && section_transaction[item?.code]?.condition ==  'COMPLETE' && !(manual_verification_list  && !manual_verification_list.verified ) && !( inReview))" class="fas fa-check-circle text-success float-end fz-25"></span>
                                   <span *ngIf="(item?.code == 'BIOMETRIC' && manual_verification_list  && !manual_verification_list.verified )" class="fas fa-check-circle text-danger float-end fz-25"></span>
                                   <span *ngIf="(item?.code == 'BIOMETRIC' &&  inReview )" class="fas fa-check-circle text-warning float-end fz-25"></span>







                                     <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'BURO' && !loading_buro" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="getDataBuro(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_buro && item?.code == 'BURO'" class="small text-dark float-end">Cargando...</span>

                                   <!--<span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('PHOTO_IDENTITY')">Completar</button>
                                    </span> -->

                                    <div *ngIf=" item?.code == 'PROFILE_DATA'"  class=" text-primary float-end me-2" >

                                        <span (click)="pocisionBottom(content)">Ver </span>

                                    </div>

                                   <!-- <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' &&  item?.code == 'SELFIE'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('SELFIE')">
                                            <span > Completar</span>

                                        </button>

                                    </span>-->

                                
                                    <div   *ngIf="(!person?.person?.file_selfie?.url  &&  item?.code == 'SELFIE' && status_approved) && (declinedStatus || approvedStatus)"  class=" text-primary float-end me-2">

                                        <i (click)="setBiometria('SELFIE')" class="fa fa-upload text-secondary" aria-hidden="true"></i>

                                    </div>

                                    <div *ngIf="item?.code  == 'SELFIE' "  class=" text-primary float-end me-2">
                                        <div>
                                            <span (click)="redirectIMG(person?.person?.file_selfie?.url)">Ver</span>
                                        </div>


                                    </div>


                                    <div   *ngIf="(!person?.person?.file_identity?.url  &&  item?.code == 'PHOTO_IDENTITY' && status_approved) && (declinedStatus || approvedStatus)"  class=" text-primary float-end me-2">

                                        <i (click)="setBiometria('PHOTO_IDENTITY')" class="fa fa-upload text-secondary" area-hidden="true" ></i>

                                    </div>



                                    <div *ngIf="item?.code == 'PHOTO_IDENTITY' && person?.person?.file_identity?.url" class=" text-primary float-end me-2">
                                        <div>
                                        <span (click)="redirectIMG(person?.person?.file_identity?.url)">Ver </span>
                                        </div>
                                    </div>



                                    <div *ngIf=" item?.code == 'BIOMETRIC'" class=" text-primary float-end me-2">
                                        <div class="d-flex justify-content-between">
                                        <div *ngIf="!loading && section_transaction && person?.person && biometria_data?.person?.person_customer?.biometric_enabled">
                                            <span class="badge bg-warning text-dark p-2 me-2">Biometría Habilitada</span>
                                        </div>
                                            <span (click)="pocisionBottomBiometric(content3)" class="" *ngIf="inReview">Revisar </span>
                                            <span (click)="pocisionBottomBiometric(content3)" *ngIf="!inReview">Ver </span>
                                        </div>
                                    </div>




                                    <!--
                                    <span *ngIf="item?.code == 'ANALYSIS'  && !loading_analysis && analysis_response " class="fas fa-check-circle text-success float-end fz-25"></span>

                                    <span *ngIf="item?.code == 'ANALYSIS'  && section_transaction[item?.code]?.condition != 'COMPLETE' && !loading_analysis && !analysis_response" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setAnalysis(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_analysis && item?.code == 'ANALYSIS'" class="small text-dark float-end">Cargando...</span>
                                 -->

                                </ng-container>

                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition !=  'COMPLETE' && section_transaction[item?.code]?.condition !=  'COMPLETE'">
                                    <span  class="small  float-end text-secondary" >Pendiente</span>
                                </ng-container>

                            </ng-container>


                            <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE'" ></ng-container>

                                   <div class="mb-2"><strong class="fw-semibold">{{item.name}}</strong></div>
                                    <span class="d-block small opacity-75">{{item.description}}</span>
                                    <span *ngIf="!loading_buro && item?.code == 'BURO' && buro" class="small text-dark mt-1">
                                     Fecha   <span class="fw-bold">{{buro.fechaConsulta | date: 'dd/MM/yyyy h:mm:ss a'}} </span>
                                      <!-- <span *ngIf="buro_score"> / Score </span>
                                      <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>

                                    <span *ngIf="item?.code == 'TERMS_AND_CONDITIONS' && person?.person?.date_response_terms_and_conditions " class="small text-dark mt-1">
                                        Fecha de aprobación  <span class="fw-bold">  {{person?.person?.date_response_terms_and_conditions | date: 'dd/MM/yyyy'}}</span>
                                         <!-- <span *ngIf="buro_score"> / Score </span>
                                         <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>

                                    <div *ngIf="item?.code == 'ANALYSIS' && !loading_analysis && analysis_response ">
                                        <div class="mt-3" >

                                            <div   class=" text-primary float-end me-2"  (click)="pocisionBottom(content2)">

                                                <span  >Ver </span>

                                            </div>
                                            <div>


                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Fecha : {{analysis_response?.date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Estatus : {{analysis_response?.showStatusValue}}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2" *ngIf="analysis_response?.approve_status">Respuesta : {{analysis_response?.approve_status}}</span>

                                            <!--
                                            <span  class="badge rounded-pill bg-secondary me-2 mt-2" *ngIf="_auth.IsPermitid(['OWNER'])" >Consultado en :

                                                <span *ngFor="let item of analysis_response?.approve_type_history;let i=index" >

                                                    <span *ngIf="i > 0">, </span>
                                                    {{item}}
                                                </span>

                                            </span>
                                            -->

                                            <div class="mt-3" *ngIf="section_transactions?.length > 0 && !loading_analysis">

                                                <button *ngIf="_auth.IsPermitid(['OWNER'])  && (status_approved != 'APPROVED' || analysis_response?.transaction_status != 'COMPLETE')" type="button" class="btn btn-primary  btn-sm mb-3 me-2 "
                                                (click)="manual_approve(person?.person?.code,true,'Aprobar')">Aprobar (Manual)</button>

                                                <button *ngIf="_auth.IsPermitid(['OWNER'])  && (status_approved != 'REFUSED' || analysis_response?.transaction_status != 'COMPLETE')" type="button" class="btn btn-danger btn-sm  mb-3"
                                                (click)="manual_approve(person?.person?.code,false,'Rechazar')">Rechazar (Manual)</button>


                                            </div>

                                        </div>

                                        </div>

                                    </div>
                         </label>


                    </div>




            <!--<pre>{{ analysis_response | json }}</pre>-->

                    <div  *ngIf="status_approved == 'BURO_PSICOMETRICO'" class="position-relative">

                        <div class="list-group-item py-3 " for="listGroupRadioGrid1">
                            <div class=" text-secondary float-end text-end">

                                <ng-container *ngIf="start_psicometrico" >

                                    <button type="button" *ngIf="activatePsico " class=" btn btn-secondary btn-sm" (click)="setStatus_psicometrico(false)">Desactivar psicométrico </button>
                                    <button type="button" *ngIf="!activatePsico " class=" btn btn-primary btn-sm" (click)="setStatus_psicometrico(true)">Activar psicométrico </button>

                                </ng-container>

                                <!--
                                <ng-container *ngIf="!start_psicometrico_load && status_approved == 'WAIT_PSICOMETRICO' && buro.start_psicometrico && !start_psicometrico">

                                    <div class="text-warning small"> En espera de resultado </div>
                                    <div class="text-secondary small">Activado {{buro.consult_psicometrico_date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                </ng-container>
                            -->

                            </div>
                            <div>
                                <strong class="fw-bold" >Psicometrico</strong>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="status_approved == 'APPROVED' && analysis_response?.showStatusValue == 'Completa'" class="position-relative mt-2">

                        <label style="    background: #FEFEFE; border: var(--bs-success) 1px solid;" class="list-group-item py-3 " for="listGroupRadioGrid1">
                            <span class=" text-secondary float-end">

                                <span  class=" text-dark fw-bold">{{person?.person?.quota | currency:'USD':'': '1.2-2'}} USD</span>
                            </span>
                            <strong class="fw-semibold">Cupo disponible</strong>
                            <br>

                            <span *ngIf="person?.person?.quota_active" class=" small text-success">Activo </span>
                            <span *ngIf="!person?.person?.quota_active" class=" small text-danger">Inactivo  </span>

                         </label>

                    </div>


                </div>



            <ng-container *ngIf="loading_buro">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>



            </ng-container>


            <div *ngIf="!loading && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>

            <ng-container *ngIf="loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>

            <div *ngIf="loading_buro " class="text-center mt-2">
                <div>Consultando a buró...</div>
            </div>

        </div>
    </div>

</div>
